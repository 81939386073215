<template>
  <div class="share_active">
    <!-- <div class="member_header">

        </div> -->
    <div class="share_cont">
      <h3><span></span>推广新用户 赠送会员<span></span></h3>
      <img src="@/assets/image/mobileActive/mobile_title.png" alt="" class="title" />
      <div class="active_countdown">
        <p>
          活动倒计时：{{time}}
        </p>
      </div>
      <div class="active_introduce">
          <h2><span></span>活动具体介绍<span></span></h2>
          <p>用户参加“推广分享”活动，推荐好友通过专属链接注册成功并购买任意会员后，分享人当月即享99次正版素材下载数量，畅享全站包含音乐、视频、AI语音、音效在内的海量优质资源！多多分享，得更多下载数量</p>
      </div>
      <img
        src="@/assets/image/mobileActive/mobile_btn.png"
        @click="registerClick"
        class="btn"
      />
      <img src="@/assets/image/mobileActive/mobile_jiantou.png" alt="" class="jiantou">
    </div>

    <!-- 已注册会员 -->
    <div class="registered_user">
        <h2><span></span>你的助力好友<span></span></h2>
        <p class="register_thead"><span>用户</span><span>助力时间</span></p>
        <div class="infinite-list-wrapper" style="overflow:auto">
            <ul v-if="userList.length !== 0">
                <li v-for="(item,index) in userList" :key="index">
                    <div class="user_info info">
                        <label>{{index+1}}</label>
                        <p>
                            <span>{{item.name}}</span>
                            <span>{{item.phone}}</span>
                        </p>
                    </div>
                    <div class="create_time info">
                        <span>{{item.create_at}}</span>
                    </div>
                </li>
            </ul>
            <div class="nolist_text" v-else>
            快去分享链接，邀请用户吧<br>邀请越多，会员越多哦
            </div>
        </div>
    </div>
    <!-- 全站资源任意下载使用 -->
    <div class="allWebsite_material">
      <div class="title">
        <p>全站资源任意下载使用</p>
        <label for="">音乐、音效、视频、AI语音</label>
      </div>
      <div class="material_cont">
        <div class="website_description">
          <div class="material_type">
            <img src="@/assets/image/mobileActive/mobileMember_icon1.png" alt="" />
            <span>自媒体平台</span>
          </div>
          <div class="material_type">
            <img src="@/assets/image/mobileActive/mobileMember_icon2.png" alt="" />
            <span>个人直播/FM</span>
          </div>
          <div class="material_type">
            <img src="@/assets/image/mobileActive/mobileMember_icon3.png" alt="" />
            <span>产品内置用途</span>
          </div>
          <div class="material_type">
            <img src="@/assets/image/mobileActive/mobileMember_icon4.png" alt="" />
            <span>家庭视频</span>
          </div>
          <div class="material_type">
            <img src="@/assets/image/mobileActive/mobileMember_icon5.png" alt="" />
            <span>个人影视作品</span>
          </div>
          <div class="material_type">
            <img src="@/assets/image/mobileActive/mobileMember_icon6.png" alt="" />
            <span>AI语音下载</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 活动规则 -->
    <div class="active_rules">
      <label>活动规则</label>
      <ul>
        <li>1、活动时间：2022年2月23日18:00:00至2022年6月15日18:00:00；</li>
        <li>2、已经注册<span>PlayList</span>的用户，分享专属链接后每一新用户在该专属链接完成任意会员权益购买，分享人即可获赠一份个人月会员，分享赠送的个人月会员有效期自被分享人完成任意会员权益购买成功时开始计算；</li>
        <li>3、推广分享赠送的个人月会员，在会员列表中将分开展示，赠送的会员不可退款、不可开具发票；</li>
        <li>4、被分享人打开链接完成任意会员权益购买后，分享人在活动页面可查看已经通过自己分享的链接完成任意会员权益购买的用户；</li>
        <li>5、分享人和被分享人可以继续参加进群送会员活动，活动详情请咨询群内<span>PlayList</span>客服助手；</li>
        <li>6、如有疑问，可联系官网客服；</li>
        <li>7、本次活动最终解释权归北京数音未来科技有限公司所有。</li>
      </ul>
    </div>

    <!-- 复制链接 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      top="30vh"
      center
      :show-close="false"
    >
      <el-result icon="success">
        <template slot="extra">
           <div class="copy-container">{{ link }}</div>
           <p>复制专属链接，去分享给其他用户，立得会员</p>
        </template>
      </el-result>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCopyAndClose"
        >复制链接</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserlist, createSharelink } from '@/api/member.js';
import { getToken } from '@/utils/author';
import { copyInfo } from '@/utils/utils'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialogVisible: false,
      timer: null,
      timers: null,
      count: 10, // 剩余名额
      day: '', // 天
      hours: '', // 时
      minutes: '', // 分
      seconds: '', // 秒
      active_endTime: new Date('2022/6/15, 18:00:00'),
      time: '',
      link: '', // 分享链接
      userList: [
        // {
        //   name: '张三是生生世世颠三倒四的', phone: '187****6623', create_at: '2022-02-21 18:11:07'
        // },
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.$store.dispatch('user/setMemberstatus', false)
    if(this.userInfo){
      this.getUserlist();
    }
    if(!this.timer){
      this.timer = setInterval(() => {
        this.FreshTime()
      }, 1000)
    }
  },
  beforeRouteLeave(to, from, next){
    //   console.log(to, from)
    if(to.fullPath !== '/activity/shareActive'){
      this.$store.dispatch('user/setMemberstatus', true)
    }
    next()
  },
  methods: {
    /**
     * 获取已注册用户列表
     */
    async getUserlist(){
      const res = await getUserlist();
      if(res.code == '0'){
        // console.log(res)
        this.userList = res.data;
      }else{
        alert(res.msg)
      }
    },
    /**
     * 刷新倒计时间
     */
    FreshTime() {
        const nowTime = new Date() // 当前时间
        const spaceTime = parseInt(
            (this.active_endTime.getTime() - nowTime.getTime()) / 1000
        )
        if (spaceTime == 0) {
            clearInterval(this.timer)
            this.timer = null
        }
        this.day =
            parseInt(spaceTime / (24 * 60 * 60))
        this.hours =
            parseInt((spaceTime / (60 * 60)) % 24) < 10
            ? '0' + parseInt((spaceTime / (60 * 60)) % 24)
            : parseInt((spaceTime / (60 * 60)) % 24)
        this.minutes =
            parseInt((spaceTime / 60) % 60) < 10
            ? '0' + parseInt((spaceTime / 60) % 60)
            : parseInt((spaceTime / 60) % 60)
        this.seconds =
            parseInt(spaceTime % 60) < 10
            ? '0' + parseInt(spaceTime % 60)
            : parseInt(spaceTime % 60)
        this.time = this.day + '天' + this.hours + '时' + this.minutes + '分' + this.seconds + '秒'
        // console.log(this.day, this.hours, this.minutes, this.seconds)
    },
    /**
     * 点击立即推广
     */
    async registerClick() {
      if(!this.userInfo){
        this.$router.push({ path: '/login' })
      }else{
        createSharelink({
          headers:{
            'token': getToken()
          }
        }).then((res)=>{
          if(res.code == '0'){
            console.log(res)
            const url = res.data.share_url.split('?')[1]; // 分享链接
            console.log(url)
            this.link = `${window.location.origin}/home?${url}`
            console.log(this.link)
            this.dialogVisible = true;
          }
        }).catch((error)=>{
          console.log(error)
        })
      }
    },
    /**
     * 关闭并且复制弹框
     */
    handleCopyAndClose() {
      copyInfo(this.link)
      this.$message.success('复制成功！')
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/shareMobile.scss";
.active_confirm {
  display: flex;
  align-items: center;
  img {
    width: 36px;
  }
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: inline-block;
    padding-left: 10px;
  }
}
// @import '~@/styles/memeber.scss';
</style>
