var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share_active"},[_c('div',{staticClass:"share_cont"},[_vm._m(0),_c('img',{staticClass:"title",attrs:{"src":require("@/assets/image/mobileActive/mobile_title.png"),"alt":""}}),_c('div',{staticClass:"active_countdown"},[_c('p',[_vm._v(" 活动倒计时："+_vm._s(_vm.time)+" ")])]),_vm._m(1),_c('img',{staticClass:"btn",attrs:{"src":require("@/assets/image/mobileActive/mobile_btn.png")},on:{"click":_vm.registerClick}}),_c('img',{staticClass:"jiantou",attrs:{"src":require("@/assets/image/mobileActive/mobile_jiantou.png"),"alt":""}})]),_c('div',{staticClass:"registered_user"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"infinite-list-wrapper",staticStyle:{"overflow":"auto"}},[(_vm.userList.length !== 0)?_c('ul',_vm._l((_vm.userList),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"user_info info"},[_c('label',[_vm._v(_vm._s(index+1))]),_c('p',[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.phone))])])]),_c('div',{staticClass:"create_time info"},[_c('span',[_vm._v(_vm._s(item.create_at))])])])}),0):_c('div',{staticClass:"nolist_text"},[_vm._v(" 快去分享链接，邀请用户吧"),_c('br'),_vm._v("邀请越多，会员越多哦 ")])])]),_vm._m(4),_vm._m(5),_c('el-dialog',{attrs:{"title":"","visible":_vm.dialogVisible,"width":"50%","top":"30vh","center":"","show-close":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-result',{attrs:{"icon":"success"}},[_c('template',{slot:"extra"},[_c('div',{staticClass:"copy-container"},[_vm._v(_vm._s(_vm.link))]),_c('p',[_vm._v("复制专属链接，去分享给其他用户，立得会员")])])],2),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleCopyAndClose}},[_vm._v("复制链接")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('span'),_vm._v("推广新用户 赠送会员"),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"active_introduce"},[_c('h2',[_c('span'),_vm._v("活动具体介绍"),_c('span')]),_c('p',[_vm._v("用户参加“推广分享”活动，推荐好友通过专属链接注册成功并购买任意会员后，分享人当月即享99次正版素材下载数量，畅享全站包含音乐、视频、AI语音、音效在内的海量优质资源！多多分享，得更多下载数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span'),_vm._v("你的助力好友"),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"register_thead"},[_c('span',[_vm._v("用户")]),_c('span',[_vm._v("助力时间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allWebsite_material"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("全站资源任意下载使用")]),_c('label',{attrs:{"for":""}},[_vm._v("音乐、音效、视频、AI语音")])]),_c('div',{staticClass:"material_cont"},[_c('div',{staticClass:"website_description"},[_c('div',{staticClass:"material_type"},[_c('img',{attrs:{"src":require("@/assets/image/mobileActive/mobileMember_icon1.png"),"alt":""}}),_c('span',[_vm._v("自媒体平台")])]),_c('div',{staticClass:"material_type"},[_c('img',{attrs:{"src":require("@/assets/image/mobileActive/mobileMember_icon2.png"),"alt":""}}),_c('span',[_vm._v("个人直播/FM")])]),_c('div',{staticClass:"material_type"},[_c('img',{attrs:{"src":require("@/assets/image/mobileActive/mobileMember_icon3.png"),"alt":""}}),_c('span',[_vm._v("产品内置用途")])]),_c('div',{staticClass:"material_type"},[_c('img',{attrs:{"src":require("@/assets/image/mobileActive/mobileMember_icon4.png"),"alt":""}}),_c('span',[_vm._v("家庭视频")])]),_c('div',{staticClass:"material_type"},[_c('img',{attrs:{"src":require("@/assets/image/mobileActive/mobileMember_icon5.png"),"alt":""}}),_c('span',[_vm._v("个人影视作品")])]),_c('div',{staticClass:"material_type"},[_c('img',{attrs:{"src":require("@/assets/image/mobileActive/mobileMember_icon6.png"),"alt":""}}),_c('span',[_vm._v("AI语音下载")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"active_rules"},[_c('label',[_vm._v("活动规则")]),_c('ul',[_c('li',[_vm._v("1、活动时间：2022年2月23日18:00:00至2022年6月15日18:00:00；")]),_c('li',[_vm._v("2、已经注册"),_c('span',[_vm._v("PlayList")]),_vm._v("的用户，分享专属链接后每一新用户在该专属链接完成任意会员权益购买，分享人即可获赠一份个人月会员，分享赠送的个人月会员有效期自被分享人完成任意会员权益购买成功时开始计算；")]),_c('li',[_vm._v("3、推广分享赠送的个人月会员，在会员列表中将分开展示，赠送的会员不可退款、不可开具发票；")]),_c('li',[_vm._v("4、被分享人打开链接完成任意会员权益购买后，分享人在活动页面可查看已经通过自己分享的链接完成任意会员权益购买的用户；")]),_c('li',[_vm._v("5、分享人和被分享人可以继续参加进群送会员活动，活动详情请咨询群内"),_c('span',[_vm._v("PlayList")]),_vm._v("客服助手；")]),_c('li',[_vm._v("6、如有疑问，可联系官网客服；")]),_c('li',[_vm._v("7、本次活动最终解释权归北京数音未来科技有限公司所有。")])])])
}]

export { render, staticRenderFns }